import { Box, Button, Container, Grid, Typography } from "@mui/material";
import React from "react";
import { makeStyles } from "@material-ui/styles";
import NewImage from '../../Assets/Career-Images/new.png';
import { useHistory } from "react-router-dom";
import './Career.css'
import AOS from 'aos';
import { useEffect } from "react";

const useStyles=makeStyles({
  textAlign: {
    textAlign: "justify",
    lineHeight: "2",
    // backgroundColor: "#33ab9f",
    "& .MuiTypography-root": {
      lineHeight: "40px",
      fontFamily: "poppins",
    },
  },
  careerGridBox: {
    boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
    justifyContent: "spaceBetween",
    padding: "30px",
    boxSizing: "borderBox",
    backgroundColor: "#009688",
    borderRadius: "10px",
    color: "white",
  },
})

export const JobOpenings = () => {
  const classes=useStyles();
  const history=useHistory();
  const currentOpening=[
    {
      position:'DFT Engineer',
      experience:'2 - 15 Years',
      location:'Bangalore',
      education:'B.Tech/M.Tech',
      skills:[
        'Minimum 2+ years of experience in DFT domain is a must',
        'Proficient in DFT architectures & methodologies that includes Scan, ATPG, MBIST (Memory BIST), LBIST (Logic BIST), BSCAN (Boundary Scan), JTAG, etc',
        'Proven knowledge of VHDL & Verilog, RTL design and micro-architecture skills',
        'Sound knowledge of tools/methodology (Industry standard Simulators and Accelerators, Synthesis, linting checks, Cadence tools, Industry standard ATPG/MBIST tools design compiler etc',
        'Efficient debugging skills and demonstrated experiences in Python, Perl & TCL scripting are a must',
        'Proven Communications skills with ability to effectively work with cross functional teams'
      ]
    },
    {
      position:'Analog Layout Engineer',
      experience:'2 - 15 Years',
      location:'Bangalore',
      education:'B.Tech/M.Tech',
      skills:[
        'Minimum 2+ years of experience in Analog Layout domain is a must',
        'Hands on Client Project Experience in developing Analog Layout/IO Layout design',
        'Expertise In using Industry Standard Tools like Cadence Virtuoso and Physical Verification Tools like Calibre, Assura',
        'Efficient debugging skills and demonstrated experiences in delivering quality layouts by taking care of DRC, LVS, ESD, LUP, Antenna and EMIR',
        'Proven Communications skills with ability to effectively work with cross functional teams',
      ]
    },
    {
      position:'STA Engineer',
      experience:'3 - 15 Years',
      location:'Bangalore',
      education:'B.Tech/M.Tech',
      skills:[
        'Minimum 3+ years of complete hands on experience in timing closure & static timing analysis STA',
        'Should have handled complex blocks/Hard macro level timing closure independently',
        'Might have exposure to subsystem flat timing closure with multiple complex blocks/Hard macro with expertise in STA tools & methodologies for timing closure with good understanding of POCV/AOCV/OCV, noise, cross-talk effects on timing',
        'Knowledge of timing corners/modes and process variations Should be an expert in handling PrimeTime / Tempus tools',
        'Should have Good knowledge over constraints, clock structure, DFT structure, Test timing etc.',
        'Knowing ECO tools like tweaker will be plus point',
        'Strong communication skills & Proficient in scripting languages (Tcl and Perl)'
      ]
    },
    {
      position:'Design Verification Engineer',
      experience:'3 - 15 Years',
      location:'Bangalore',
      education:'B.Tech/M.Tech',
      skills:[
        'Minimum 3+ Years of working experience as Design Verification Engineer',
        'Good working experience in IP or SoC or ASIC or FPGA',
        'Subsystem verification using SV/UVM on Client projects',
        'Must have good PCIe knowledge and experience verifying PCIe project',
        'Experience in writing test plan, test cases',
        'Familiar with code coverage and functional coverage',
        'Good scripting skills are a plus',
        'Good interpersonal skills and effectively work well within a team is a must'
      ]
    },
    {
      position:'React JS Developer',
      experience:'2 Years',
      location:'Chennai / Bangalore',
      education:'B.Tech /M.Tech / B.Sc (Computer Science) / BCA',
      skills:[
        'Minimum 2 years of working experience as a react.js developer',
        'In-depth knowledge of JavaScript, CSS, HTML, and front-end languages',
        'Extensive knowledge of REACT tools including React.js, Webpack, Enzyme, Redux, and Flux',
        'Good working Exposure with user interface design',
        'Working knowledge of testing frameworks including Mocha and Jest',
        'Experience with browser-based debugging and performance testing software',
        'Excellent troubleshooting skills and communication Skills',
      ]
    },
    
    ]
    useEffect(() => {
      AOS.init({
        disable: 'mobile',
        duration : 2000
      });
    }, []);
  return (
    <Grid
      container
      spacing={2}
      rowGap={10}
    //   className={classes.textAlign}
      justifyContent={"space-between"}
      sx={{ marginTop: "0" }}
      direction={"column"}
      id={'currentOpenings'}
    >
      <Grid>
        <div
          className="careerImage"
          style={{
            height: "350px",
            marginBottom: "100px",
            position: "relative",
          }}
        >
          <img
            src="https://wallpapercave.com/wp/wp4863911.jpg"
            alt="Career Images"
            width="100%"
            height="100%"
          />
          <div className="headingTitle">
            <h1 className={'headingText'} style={{fontSize:'65px'}}>
              Current Openings
            </h1>
          </div>
        </div>
      </Grid>
      {/* <Grid item xs={12}> */}
      {/* <Container>
          <Grid container spacing={2}> */}
      <Grid item xs={12}>
        <Container>
          <Grid
            container
            spacing={2}
            rowGap={"90px"}
            width={"100%"}
            className={classes.textAlign}
            marginLeft={"0"}
          >
            {/* <Grid container item rowGap={'40px'}> */}

            {currentOpening.map((job,i)=>{
              return(
                <Grid xs={12} className={classes.careerGridBox} key={i} data-aos='zoom-in-up'>
                  <Typography variant="h5" sx={{textDecoration:'none',textAlign:'left'}}>{job.position}&nbsp;<span>
                    <img src={NewImage} alt='New-Image' width='50px' height='38px'/>
                    </span></Typography>
                  <ul>
                    <li><strong>Experience -</strong> {job.experience}</li>
                    <li><strong>Location -</strong> {job.location}</li>
                    <li><strong>Education -</strong> {job.education}</li>
                  </ul>
                  <Typography variant="body1" sx={{textDecoration:'underline',textUnderlineOffset:'6px'}}>Desired Skills :</Typography>
                  <ul style={{marginBottom:'38px'}}>
                    {job.skills.map((skill,index)=>{
                      return(
                      <li key={index}>{skill}</li>
                      )
                    }
                    )}
                  </ul>
                  <Button variant="contained" sx={{backgroundColor:'#00695f!important'}} onClick={()=>history.push('/apply-online')}>Apply Now</Button>
                </Grid>
              )
            })}
            
          </Grid>
        </Container>
      </Grid>
    </Grid>
  );
};
