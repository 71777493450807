import React from "react";
import "./Footer.css";

//Bootstrap Grid Components
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

//MUI ICons
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import InstagramIcon from "@mui/icons-material/Instagram";
import { Grid, Stack, Typography } from "@mui/material";
import whitelogo from '../../Assets/white-logo.png'
import { styled } from "@mui/material/styles";

const ResponsiveFooter=styled(Grid)(({theme})=>({
  [theme.breakpoints.down('sm')]:{
    height: '350px',
    rowGap: '20px',
    flexDirection: 'row',
    alignContent: 'space-evenly',
    '& .footerStack':{
      height:'auto',
    },
    '& .footerStack1':{
      height:'auto',
    }
  }
}))

export const Footer = () => {
  return (
    
    <Grid container bgcolor={"#0d222d"} sx={{marginTop:"100px",padding:'0px 20px'}}>
        <ResponsiveFooter container rowGap={'10px'} justifyContent={'space-between'}>
      <Grid item xs={12} sm={4}>
        <Stack direction={"row"} className={'footerStack'}>
            <img src={whitelogo} alt={'whitelogo'} height='40px'/>
            <Typography variant='body1' sx={{color:'white',margin:0,fontSize:'20px'}}><strong>mSPANd</strong>
                <Typography variant='body1' sx={{fontSize:'13px'}}><em>Every Engineers Dream...</em></Typography>
            </Typography>
        
            {/* <p style={{color:'white',display:'inline',marginLeft:'15px'}}>mSPANd<p>Every Engineers Dream...</p></p> */}
        </Stack>
      </Grid>
      <Grid item xs={12} sm={3}>
      <Stack Container direction={'column'}  className={'footerStack1'}>
        <p style={{color:'white'}}>Follow us on</p>
        <Stack Container direction={'row'} sx={{color:'white',justifyContent:'center',alignItems:'center',columnGap:'17px'}}>
            <a href="https://www.linkedin.com/company/mspand-technologies-private-ltd/" className="smLink" target='_blank'>
              <LinkedInIcon sx={{cursor:'pointer'}}/>
            </a>
            <a href="https://www.facebook.com/profile.php?id=100010016111639" className="smLink" target='_blank'>
                <FacebookIcon sx={{cursor:'pointer'}}/>
            </a>
            <a href="https://www.instagram.com/mspandtech/" className="smLink" target='_blank'>
                <InstagramIcon sx={{cursor:'pointer'}}/>
            </a>
              <a href=" https://twitter.com/mspand4" className="smLink" target='_blank'>
                <TwitterIcon sx={{cursor:'pointer'}}/>
              </a>
            </Stack>
            </Stack>
        </Grid>
      <Grid item xs={12} sx={{display:'flex',justifyContent:'flex-end',alignItems:"center",flexDirection:'column'}}>
        <p style={{color:'white'}}>&copy;{' '}2023. All Rights Reserved</p>
      </Grid>
      </ResponsiveFooter>
    </Grid>
    
    //     <div className='footContent'>
    //         <Container fluid>
    //             <div className='footer'>
    //                 <Row>
    //                     <Col>
    //                         <Row>
    //                             <Col style={{ padding: 0, margin: 0, }}>
    //                                 <img
    //                                     src="http://dfttraining.in/MSME/MSPAND/img/logo.png"
    //                                     alt="logo"
    //                                     width="75px"
    //                                     height="75px"
    //                                 />
    //                             </Col>
    //                             <Col>
    //                                 <span
    //                                     style={{
    //                                         padding: 0,
    //                                         margin: 0,
    //                                         color: "white",
    //                                         fontSize: "2rem",
    //                                         fontWeight: "bolder"
    //                                     }}>mSPANd
    //                                 </span>
    //                                 <p
    //                                     style={{
    //                                         padding: 0,
    //                                         margin: 0,
    //                                         color: "white",
    //                                         fontSize: "1rem"
    //                                     }}>Every Engineers Dream
    //                                 </p>
    //                             </Col>
    //                         </Row>

    //                     </Col>
    //                     <Col>
    //                         <p className='fooTitle'>OUR SERVICES</p>
    //                         <p className='fooBody'>Home</p>
    //                         <p className='fooBody'>About Us</p>
    //                         <p className='fooBody'>Career</p>
    //                         <p className='fooBody'>Apply Online</p>
    //                     </Col>
    //                     <Col>
    //                         <p className='fooTitle'>COMPANY</p>
    //                         <p className='fooBody'>Terms of Use</p>
    //                         <p className='fooBody'>Contact Us</p>
    //                     </Col>
    //                     <Col>
    //                         <p className='fooTitle'>FOLLOW</p>
    //                         <p className='fooBody'> <InstagramIcon /> <FacebookIcon /> <TwitterIcon /> <LinkedInIcon /></p>
    //                     </Col>
    //                 </Row>

    //             </div>
    //         </Container>

    //     </div>
    // )
  );
};

// export default Footer;
