// Shivanesh
import { Box, Container, Grid, Stack, Typography } from "@mui/material";
import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/styles";
import about1 from "../../Assets/About-Images/about1.jpg";
import about2 from "../../Assets/About-Images/about2.jpg";
import "./ProSerPage.css";
// import { useTheme } from '@mui/material/styles';
// import useMediaQuery from '@mui/material/useMediaQuery';
import { styled } from "@mui/material/styles";
import AOS from 'aos';
import 'aos/dist/aos.css';

const useStyles = makeStyles({
  textAlign: {
    textAlign: "justify",
    lineHeight: "2",
    // backgroundColor: "#33ab9f",
    "& .MuiTypography-root": {
      lineHeight: "40px",
    },
  },
  // aboutImg: {
  //   width: "100%",
  //   height: "95%",
  //   // borderRadius: "35px",
  //   clipPath: polygon(25% 0% 100% 0% 75% 100% 0% 100%),
  //   marginTop: "9px",
  // },
  span: {
    fontFamily: 900,
  },
});

const Responsive=styled(Typography)(({theme})=>({
    textAlign: "center",
    fontFamily: "Noto Serif Georgian",
    padding:"60px",
    boxSizing:"border-box",
      [theme.breakpoints.down('sm')]:{
        fontSize:'42px',
      }
}))
const expansion = [
  {
    term: "Test Points",
    def: "These are small connectors or pads on the product that allow test equipment to access internal signals and perform tests.",
  },
  {
    term: "Built-in Self-Test (BIST)",
    def: "This involves incorporating test circuitry into the product that can run diagnostic tests without the need for external test equipment.",
  },
  {
    term: "Boundary Scan",
    def: "This technique uses a special set of test pins to access internal signals and perform tests on the product's internal logic.",
  },
  {
    term: "JTAG",
    def: "Joint Test Action Group (JTAG) is a standard for testing printed circuit boards (PCBs) using boundary scan techniques.",
  },
  {
    term: "Functional Testability",
    def: "This technique involves designing the product in such a way that its functions can be easily tested and verified.",
  },
  {
    term: "Accessibility",
    def: "This involves designing the product so that it can be easily accessed for testing and maintenance.",
  },
];


export const ProSerPage = () => {
  const classes = useStyles();
  // const theme = useTheme(); 
  // const matches=useMediaQuery(theme.breakpoints.up('md'));
  useEffect(() => {
    AOS.init({
      duration : 2000
    });
  }, []);
  return (
    <Grid container
      spacing={2}
      rowGap={10}
      justifyContent={"space-between"}
      sx={{ marginTop: "0" }}
      direction={"column"}
    >
      <Grid>
      <div style={{height:'350px',position:'relative'}}>
    <img
         src="https://wallpaperaccess.com/download/black-office-8061907"
         alt="Contact"
         width="100%"
         height="100%"
     />
     <div className='headingTitle'>
     <h1 className={'headingText'} style={{fontSize:'76px'}}>Foot Prints</h1>
     </div>
     </div>
        
      </Grid>
      <Grid item xs={12}>
        <Container>
          <Grid
            container
            spacing={2}
            className={classes.textAlign}
            rowGap={8}
            columnGap={1}
            justifyContent={"space-between"}
          >
            <Grid container item>
              <Grid container item className={"gridBox"} data-aos='fade-right'>
                <Grid item xs={12} md={5.5}>
                  <Stack spacing={5} >
                    <Box>
                      <Typography variant="h5">VLSI - DFT</Typography>
                      <Typography variant="body1">
                        Design for Testability (DFT) – A front end VLSI design
                        have Boundary Scan or JTAG, Scan & ATPG and MBIST to
                        examine the Integrated circuit
                      </Typography>
                    </Box>
                    <Box>
                      <Typography variant="h5">VLSI - PD</Typography>
                      <Typography variant="body1">
                        Physical Design (PD)-A VLSI physical design is creating
                        detailed layouts for the integrated circuits using
                        specialized software.
                      </Typography>
                    </Box>
                  </Stack>
                </Grid>
                <Grid item xs={12} md={5.5}>
                  <img src={about1} alt="vlsi" className='aboutImg'/>
                </Grid>
              </Grid>
            </Grid>
            <Grid
              item
              container
              justifyContent={"space-between"}
              className={classes.textAlign}
            >
              <Grid container item className={"gridBox"} direction={{ xs: "row-reverse" }} rowGap={'20px'} data-aos='fade-left' data-aos-once='true'>
                <Grid item xs={12} md={5.5} className={'animateText'}>
                  <Box>
                    <Typography variant="h5">DFT</Typography>
                    <Typography variant="body1">
                      DFT (Design for Testability) is a process of designing a
                      product in a way that makes it easy to test and diagnose
                      defects or malfunctions. Techniques include test points,
                      built-in self-test, boundary scan, JTAG, functional
                      testability and accessibility. These techniques improve
                      product reliability and quality, and reduce testing cost
                      and time.
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={12} md={5.5}>
                  <img src={about2} alt="vlsi" className='aboutImg'/>
                </Grid>
                <Grid item xs={12}>
              <Box>
                {/* <Typography variant="h5">Expansion</Typography> */}
                {expansion.map((e, i) => {
                  return (
                    <Typography variant="body1" key={i}>
                      <Typography
                        variant="body1"
                        sx={{ fontWeight: 700, display: "inline" }}
                      >
                        {e.term} -{" "}
                      </Typography>
                      {e.def}
                    </Typography>
                  );
                })}
              </Box>
            </Grid>
              </Grid>
            </Grid>
            {/* <Grid container item>
              <Grid container item className={"gridBox"} data-aos='zoom-in'>
            
            </Grid>
            </Grid>          */}
          </Grid>
        </Container>
      </Grid>
    </Grid>
  );
};