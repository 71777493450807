import { Avatar, Stack } from "@mui/material";
import React, { useState } from "react";
import Carousel from "react-bootstrap/Carousel";
import Cara1 from "../../Assets/Carousel-Images/cara1.png";
import Cara2 from "../../Assets/Carousel-Images/cara2.jpg";
import Cara3 from "../../Assets/Carousel-Images/cara3.jpg";
import MaleIcon from "../../Assets/male-Icon.png";
import "./Testimonial.css";

function Testimonial() {
  //   const [index, setIndex] = useState(0);

  //   const handleSelect = (selectedIndex, e) => {
  //     setIndex(selectedIndex);
  //   };

  const testimonialArray = [
    {
      iconImg:MaleIcon,
      name: "Ramesh G",
      position: "DFT Engineer",
      desc: "I have been working here for the past one year and my managers have provided guidance and support that encourages me to take on new responsibilities and projects. My colleagues are the most supportive and knowledgeable individuals",
    },
    {
      name: "Naveenkumar P",
      position: "DFT Engineer",
      desc: "I joined MSPAND to get knowledge on DFT and it helped me to achieve a step ahead in the professional career of my interests. The co-workers is very interactive and knowledgeable giving solutions for all doubts either silly or even conceptual.",
    },
  ];

  return (
    <Carousel className={"testCarousel"}>
      {testimonialArray.map((test, i) => {
        return (
          <Carousel.Item>
            <Carousel.Caption>
              <Avatar className={"testAvatar"} src={MaleIcon} />
              <h3>
                {test.name}
                <p className="abc">{test.position}</p>
              </h3>

              <p>
                {'" '}
                {test.desc}
                {' "'}
              </p>
            </Carousel.Caption>
          </Carousel.Item>
        );
      })}
    </Carousel>
    // <Carousel activeIndex={index} onSelect={handleSelect}>
    //   <Carousel.Item>
    //     {/* <img
    //       className="d-block w-100"
    //       src={Cara1}
    //       alt="First slide"
    //     /> */}
    //     <Avatar src="/broken-image.jpg" />
    //     <Carousel.Caption>
    //       <h3>First slide label</h3>
    //       <p>Nulla vitae elit libero, a pharetra augue mollis interdum.</p>
    //     </Carousel.Caption>
    //   </Carousel.Item>
    //   <Carousel.Item>
    //     {/* <img
    //       className="d-block w-100"
    //       src={Cara2}
    //       alt="Second slide"
    //     /> */}
    //     <Avatar src="/broken-image.jpg" />

    //     <Carousel.Caption>
    //       <h3>Second slide label</h3>
    //       <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
    //     </Carousel.Caption>
    //   </Carousel.Item>
    //   <Carousel.Item>
    //     {/* <img
    //       className="d-block w-100"
    //       src={Cara3}
    //       alt="Third slide"
    //     /> */}
    //     <Avatar src="/broken-image.jpg" />

    //     <Carousel.Caption>
    //       <h3>Third slide label</h3>
    //       <p>
    //         Praesent commodo cursus magna, vel scelerisque nisl consectetur.
    //       </p>
    //     </Carousel.Caption>
    //   </Carousel.Item>
  );
}
export default Testimonial;
