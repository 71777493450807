// Shivanesh

import { Box, Container, Grid, Paper, Typography } from "@mui/material";
import React from "react";
import { makeStyles } from "@material-ui/styles";
import '../../App.css'
import './AboutPage.css'
import { styled } from "@mui/material/styles";

const useStyles = makeStyles({
  textAlign: {
    textAlign: "justify",
    lineHeight: "2",
    padding: '30px',
    backgroundColor:'#009688!important',
    color:'white!important',
    "& .MuiTypography-root": {
      lineHeight: "40px",
    },
  },
  aboutImg: {
    width: "100%",
    height: "95%",
    borderRadius: "35px",
    marginTop: "9px",
  },
  span: {
    fontFamily: 900,
  },
});
const Responsive=styled(Typography)(({theme})=>({
  textAlign: "center",
  fontFamily: "Noto Serif Georgian",
  padding:"60px",
  boxSizing:"border-box",
    [theme.breakpoints.down('sm')]:{
      fontSize:'42px',
    }
}))

export const AboutPage = () => {
  const classes = useStyles();
  return (
    // <Container sx={{ marginTop: "145px" }}>
    <Grid container
    spacing={2}
    rowGap={10}
    justifyContent={"space-between"}
    sx={{ marginTop: "0" }}
    direction={"column"}
  >
    <Grid>
    <div style={{height:'350px',position:'relative'}}>
    <img
         src="https://wallpaperaccess.com/download/black-office-8061908"
         alt="Contact"
         width="100%"
         height="100%"
     />
     <div className='headingTitle'>
     <h1 style={{fontSize:'76px',textAlign:'center',margin:0}}>About</h1>
     </div>
     </div>
      
    </Grid>
    
        <Grid item xs={12}>
        <Container>
          <Paper elevation={3} className={classes.textAlign}>
          
            <Typography variant="body1" gutterBottom>
              <strong>&emsp;&emsp;&emsp;MSPAND Technologies</strong> (A registered private limited company under
              Ministry of Corporate Affairs, Govt. of India) is a VLSI company
              with the vision to create a bench mark in the industry.We are a
              Multi-Operation company who works on Product Development in the
              field of IoT, VLSI and Embedded systems. MSPAND Technologies takes
              the privilege of providing opportunities for an Engineer to
              explore his/her talents in the areas of DFT, Physical Design and
              Layout. We also offers technical training for fresh Engineering
              graduates to improve their skills and for experienced
              professionals to strengthen their knowledge as well as cross
              domain exposure. We also support on the research work carried out
              by the research scholars on need basis.
            </Typography>
          </Paper>
          </Container>
        </Grid>
      </Grid>
    // 
  );
};
