import React from "react";
import CarouselItems from "./CarouselItems";
import Cara1 from "../../../Assets/Carousel-Images/cara1.png";
import Cara3 from "../../../Assets/Carousel-Images/cara3.jpg";
import Cara4 from "../../../Assets/Carousel-Images/cara4.png";
import Carousel from "react-material-ui-carousel";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { Stack } from "@mui/material";
import { styled } from "@mui/material/styles";


export const CarouselSlider = () => {
  var items = [
    {
      name: Cara1,
      description: "Mspand Technologies Private Limited is a Semiconductor company was founded in 2014 and has headquarters in Tamil Nadu, India",
    },
    {
      name: Cara3,
      description: "We employ the latest technologies and best practices to ensure that our products are robust, secure, scalable and user-friendly",
    },
    {
      name: Cara4,
      description: "We strive to provide our clients with a comprehensive suite of services, from concept and design to deployment and maintenance",
    },
  ];
  return (
    <Stack>
      <Carousel
        PrevIcon={
            <ArrowBackIosIcon style={{ fontSize: "50px", color: "GrayText",marginLeft:'12px' }} />
          }
          NextIcon={
            <ArrowForwardIosIcon
              style={{ fontSize: "50px", color: "GrayText" }} />
          }
           
        navButtonsAlwaysVisible
        interval={4000}
        animation={"slide"}
        duration={1000}
        swipe={false}
        
        navButtonsWrapperProps={{
          // Move the buttons to the bottom. Unsetting top here to override default style.
          style: {
            bottom: "0",
            top: "unset",
            // backgroundColor: "white",
          },
        }}
        navButtonsProps={{
          // Change the colors and radius of the actual buttons. THIS STYLES BOTH BUTTONS
          style: {
            borderRadius: 0,
            background: "none",
          },
        }}
      >
        
        {items.map((item, index) => (
            
          <CarouselItems key={index} item={item} />
          
        ))}      
      </Carousel>
      </Stack>
  );
};