import React from "react";
import { Paper, Button, Typography } from "@mui/material";
import "./CarouselStyle.css";
import { styled } from "@mui/material/styles";

const CarouselResponsive = styled(Paper)(({ theme }) => ({
  [theme.breakpoints.down("sm")]: {
    height: "350px",
    "& .caption": {
      position: "absolute",
      top: "53%",
      left: "35%",
      transform: "translate(-50%, -50%)",
      textAlign: "center",
      width: "39%",
    },
    "& .imgText": {
      fontSize: "12px",
      letterSpacing: "1px",
      wordSpacing: "1px",
      lineHeight: "20px",
      fontWeight: "500",
      margin: "10px 0",
    },
    "& .checkButton": {
      lineHeight: "29px",
      fontSize: "10px",
      padding: "0px 10px",
      boxSizing: "borderBox",
    },
  },
}));

function CarouselItems(props) {
  return (
    <CarouselResponsive
      sx={{ boxShadow: "none", position: "relative", height: "592px" }}
    >
      <img
        src={props.item.name}
        style={{ width: "100%", height: "100%" }}
        className={"sliderImg"}
      />
      <div className="caption">
        <Typography variant="h4" className="imgText">
          {props.item.description}
        </Typography>
        <Button className="checkButton" variant="contained">
          Check it out!
        </Button>
      </div>
    </CarouselResponsive>
  );
}
export default CarouselItems;
