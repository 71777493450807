import React from 'react'
import { CarouselSlider } from './Carousel/CarouselSlider'
import { ProSerPage } from '../Pro-Ser-Page/ProSerPage'
import { Box, Container, Grid, Stack, Typography } from '@mui/material'
import { makeStyles } from "@material-ui/styles";
import about1 from "../../Assets/About-Images/about1.jpg";
import about2 from "../../Assets/About-Images/about2.jpg";


const useStyles = makeStyles({
  textAlign: {
    textAlign: "justify",
    lineHeight: "2",
    // backgroundColor: "#33ab9f",
    "& .MuiTypography-root": {
      lineHeight: "40px",
    },
  },
  // aboutImg: {
  //   width: "100%",
  //   height: "95%",
  //   // borderRadius: "35px",
  //   clipPath: polygon(25% 0% 100% 0% 75% 100% 0% 100%),
  //   marginTop: "9px",
  // },
  span: {
    fontFamily: 900,
  },
});


export const HomePage = () => {
  const classes = useStyles();
  return (
    <div>
        <CarouselSlider/>
        <Container sx={{marginTop:"100px"}}>
          <Grid
            container
            spacing={2}
            className={classes.textAlign}
            rowGap={8}
            columnGap={1}
            justifyContent={"space-between"}
          >
            
            <Grid container item>
              <Grid container item className={"gridBox"}>
                <Grid item xs={12} md={5.5}>
                  <Stack spacing={5} >
                    <Box>
                      <Typography variant="h5">VLSI - DFT</Typography>
                      <Typography variant="body1">
                        Design for Testability (DFT) – A front end VLSI design
                        have Boundary Scan or JTAG, Scan & ATPG and MBIST to
                        examine the Integrated circuit
                      </Typography>
                    </Box>
                    <Box>
                      <Typography variant="h5">VLSI - PD</Typography>
                      <Typography variant="body1">
                        Physical Design (PD)-A VLSI physical design is creating
                        detailed layouts for the integrated circuits using
                        specialized software.
                      </Typography>
                    </Box>
                  </Stack>
                </Grid>
                <Grid item xs={12} md={5.5}>
                  <img src={about1} alt="vlsi" className='aboutImg' />
                </Grid>
              </Grid>
            </Grid>
            <Grid
              item
              container
              justifyContent={"space-between"}
              className={classes.textAlign}
            >
              <Grid container item className={"gridBox"} direction={{ xs: "row-reverse" }}>
                <Grid item xs={12} md={5.5}>
                  <Box>
                    <Typography variant="h5">DFT Product</Typography>
                    <Typography variant="body1">
                      DFT (Design for Testability) is a process of designing a
                      product in a way that makes it easy to test and diagnose
                      defects or malfunctions. Techniques include test points,
                      built-in self-test, boundary scan, JTAG, functional
                      testability and accessibility. These techniques improve
                      product reliability and quality, and reduce testing cost
                      and time.
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={12} md={5.5}>
                  <img src={about2} alt="vlsi" className='aboutImg' />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          </Container>
    </div>
  )
}



