import React, { useEffect, useState } from "react";
import "./Career.css";
import ApplyOnline from "./ApplyOnline";
import { makeStyles } from "@material-ui/styles";

//Bootstrap Grid Components
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

//Material Ui Icons
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { Box, Button, Grid, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Typography } from "@mui/material";
import Career1 from "../../Assets/Career-Images/career1.png";
import StarIcon from '@mui/icons-material/Star'
import { Link, useHistory } from "react-router-dom";
import Testimonial from "./Testimonial";
import AOS from 'aos';
import { styled } from "@mui/material/styles";

const useStyles = makeStyles(
  {
    textAlign: {
      textAlign: "justify",
      lineHeight: "2",
      // backgroundColor: "#33ab9f",
      "& .MuiTypography-root": {
        lineHeight: "40px",
        fontFamily: "poppins",
      },
    },
    whyMspand: {
      width: "80%",
      height: "500px",
      margin: "0 auto",
    },
    careerGridBox: {
      boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
      justifyContent: "spaceBetween",
      padding: "30px",
      boxSizing: "borderBox",
      backgroundColor: "#009688",
      borderRadius: "10px",
      color: "white",
    },
    openingsBtn:{
      backgroundColor:'#009688!important',
      '&:hover':{
        backgroundColor:'#00695f!important'
      }
    }
  })

  // aboutImg: {
  //   width: "100%",
  //   height: "95%",
  //   // borderRadius: "35px",
  //   clipPath: polygon(25% 0% 100% 0% 75% 100% 0% 100%),
  //   marginTop: "9px",
  // },
  // span: {
  //   fontFamily: 900,
  // },
  const WhyMspand=styled(Box)(({theme})=>({
    width: "80%",
      height: "500px",
      margin: "0 auto",
      [theme.breakpoints.down('sm')] : {
        height:'250px',
        width:'100%'
      }
  }));

export const CareerPage = () => {
  const classes = useStyles();
  const [downArrow, setDownArrow] = useState(true);
  const history=useHistory()

  const handleHideVisible = () => {
    setDownArrow(!downArrow);
    console.log(downArrow);
  };
  const empBenefits=[
    'Employee Friendly Environment',
    'Excellent opportunity to work with Talented Professionals',
    'Industry Standard Compensation',
    'Professional trainings and cross cultural Learnings',
    'Better Work Life Balance',
    'Annual Paid Holidays',
    'Annual Outings and Recreational get-togethers',
    'Annual Bonus and Increments',
  ];
  useEffect(() => {
    AOS.init({
      disable: 'mobile',
      
    });
  }, []);
  return (
    // <div>
    //     <div className='careerImage' style={{height:'350px',marginBottom:'100px',position:'relative'}}>
    //         <img
    //             src="https://wallpapercave.com/wp/wp4863911.jpg"
    //             alt="Career Images"
    //             width="100%"
    //             height="100%"
    //         />
    //         <div className='headingTitle'>
    //           <h1 style={{fontSize:'76px',textAlign:'center',margin:0}}>Careers</h1>
    //         </div>
    //     </div>
    //     <Container>
    //     <Grid
    //         item
    //         container
    //         justifyContent={"space-between"}
    //         className={classes.textAlign}
    //       >
    //         <Grid container item className={"gridBox"} direction={{ xs: "row-reverse" }}>
    //           <Grid item xs={12} md={5.5}>
    //             <Box>
    //               <Typography variant="h5">What We Carry On</Typography>
    //               <Typography variant="body1">
    //                   <li>
    //               Test Points: These are small connectors or pads on the product that allow test equipment to access internal signals and perform tests.
    //               </li>
    //               <li>
    //               Built-in Self-Test (BIST): This involves incorporating test circuitry into the product that can run diagnostic tests without the need for external test equipment.
    //               </li>
    //               <li>Boundary Scan: This technique uses a special set of test pins to access internal signals and perform tests on the product's internal logic.
    //               </li>
    //               </Typography>
    //             </Box>
    //           </Grid>
    //           <Grid item xs={12} md={5.5}>
    //             <img src='https://www.boydcorp.com/images/easyblog_articles/186/b2ap3_large_Semiconductor-Packaging.jpg' alt="vlsi" className='aboutImg' />
    //           </Grid>
    //         </Grid>
    //       </Grid>
    //       </Container>
    //     <div className='careerContent'>
    //         <Container>
    //   {/* //           <Row>
    //   //               <Col md={6} sm={12} xs={12}>
    //   //                   <p className='careerTitle'>What We Carry On</p>
    //   //                   <p className='careerBody'>Test Points: These are small connectors or pads on the product that allow test equipment to access internal signals and perform tests.
    //   //                       Built-in Self-Test (BIST): This involves incorporating test circuitry into the product that can run diagnostic tests without the need for external test equipment.
    //   //                       Boundary Scan: This technique uses a special set of test pins to access internal signals and perform tests on the product's internal logic.
    //   //                   </p>
    //   //               </Col>
    //   //               <Col md={6} sm={12} xs={12}>
    //   //                   <img
    //   //                       src="https://www.boydcorp.com/images/easyblog_articles/186/b2ap3_large_Semiconductor-Packaging.jpg"
    //   //                       alt="dft"
    //   //                       width="100%"
    //   //                       height="100%"
    //   //                       className='carImgBody'
    //   //                   />
    //   //               </Col>
    //   //           </Row> */}
    //             <Row>
    //                 <Col xxl={2} xl={2} lg={2} md={1} sm={12} xs={12}></Col>
    //                 <Col xxl={8} xl={8} lg={8} md={10} sm={12} xs={12}>
    //                     <div className='ApplyOnline'>
    //                     <h2 style={{ paddingBottom: "30px" }}>Apply Online</h2>
    //                     <p>Your future will be determined by what you do now.</p>
    //                         {
    //                             downArrow
    //                                 ?
    //                                 <button
    //                                     type="button"
    //                                     onClick={()=>handleHideVisible()}
    //                                     className="ArrowIcon"
    //                                     ><KeyboardArrowDownIcon className='keyDownArrow'/>
    //                                 </button>
    //                                 :
    //                                 <div>
    //                                     <button
    //                                         type="button"
    //                                         onClick={()=>handleHideVisible()}
    //                                         className="ArrowIcon"
    //                                         ><KeyboardArrowUpIcon className='keyDownArrow'/>
    //                                     </button>
    //                                     <ApplyOnline />
    //                                 </div>
    //                         }
    //                     </div>
    //                 </Col>
    //                 <Col xxl={2} xl ={2} lg={2} md={1} sm={12} xs={12}></Col>
    //             </Row>
    //         </Container>
    //     </div>
    // </div>
    <Grid
      container
      spacing={2}
      rowGap={'50px'}
      className={classes.textAlign}
      justifyContent={"space-between"}
      sx={{ marginTop: "0" }}
      direction={"column"}
    >
      <Grid>
        <div
          id='#abc'
          className="careerImage"
          style={{
            height: "350px",
            marginBottom: "70px",
            position: "relative",
          }}
        >
          <img
            src="https://wallpapercave.com/wp/wp4863911.jpg"
            alt="Career Images"
            width="100%"
            height="100%"
          />
          <div className="headingTitle">
            <h1 style={{ fontSize: "76px", textAlign: "center", margin: 0 }}>
              Careers
            </h1>
          </div>
        </div>
      </Grid>
      {/* <Grid item xs={12}> */}
      {/* <Container>
          <Grid container spacing={2}> */}
      <Grid item xs={12}>
        <Container>
          <Grid
            container
            spacing={2}
            // rowGap={"90px"}
            width={"100%"}
            marginLeft={"0"}
          >
            {/* <Grid container item rowGap={'40px'}> */}
            <Grid xs={12} data-aos='zoom-in-up'>
              <WhyMspand>
                <img
                  src={Career1}
                  alt="Why MSPAND?"
                  width="100%"
                  height="100%"
                />
              </WhyMspand>
            </Grid>
            <div className="gridPadding" id='mspandGrowing'>
            <Grid xs={12} className={classes.careerGridBox}>
              <Typography variant="h5" sx={{textAlign:'left'}}>MSPAND - The Growing Company</Typography>
              <Typography variant="body1">
                MSPAND puts its effort on consistent growth in the VLSI design
                services and into software development. The founders of the
                company are in the semiconductor industry for more than 22+
                years. The strong knowledge and exposure in the semiconductor
                field helps the budding engineers and experienced professionals
                to grow further in their career ladder. MSPAND has marked its
                footprints in both VLSI front end and backend Verification like
                VLSI Design For Test(DFT), Physical design, Analog Layout,
                Design verification and software Web development.
              </Typography>
            </Grid>
            </div>
            <div className="gridPadding" id='employeeBenefits'>
            <Grid xs={12} className={classes.careerGridBox} data-aos='fade-right'>
              <Typography variant="h5">Employee Benefits</Typography>
              <List>
                {empBenefits.map((benefits,i)=>{
                  return(
                    <ListItem key={i} sx={{paddingTop:'0',alignItems:'start'}}>
                  <ListItemIcon sx={{marginTop:"10px"}}>
                    <StarIcon sx={{color:"white"}}/>
                  </ListItemIcon>
                  <ListItemText sx={{lineHeight:'30px'}} primary={benefits} />
              </ListItem>
                  )
                })}
              
              </List>             
            </Grid>
            </div>
            <div className="gridPadding" >
            <Grid xs={12} justifyContent={"center"} display={'flex'} >
              <Button variant="contained" className={classes.openingsBtn} onClick={()=>history.push('/job-openings')} data-aos="zoom-in">Click here to view for Current Openings</Button>
            </Grid>
            </div>
            <div className="gridPadding" id={'testimonial'}>
            <Typography variant="h5" sx={{fontSize:'32px',color:"#00695f",textAlign:'center'}}>Testimonial</Typography>
            <Grid xs={12} justifyContent={"center"} display={'flex'} data-aos="flip-right">
              <Testimonial />
            </Grid>         
            </div>
          </Grid>
          {/* </Grid> */}
        </Container>
      </Grid>
    </Grid>
  );
};