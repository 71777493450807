import { Button, Container, Grid, Stack, TextField, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/styles";
import { styled } from "@mui/material/styles";
import axios from 'axios';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';


const useStyles = makeStyles({
  textAlign: {
    textAlign: "justify",
    lineHeight: "2",
    boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
    justifyContent: "spaceBetween",
    padding: "30px",
    boxSizing: "borderBox",
    backgroundColor: "#009688",
    borderRadius: "10px",
    color: "white",
    marginTop: "250px",
    // backgroundColor: "#33ab9f",
    "& .MuiTypography-root": {
      lineHeight: "40px",
      fontFamily: "poppins",
    },
  },
  careerGridBox: {
    boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
    justifyContent: "spaceBetween",
    padding: "30px",
    boxSizing: "borderBox",
    backgroundColor: "#009688",
    borderRadius: "10px",
    color: "white",
  },
  applyGridBox: {
    border: "3px solid #00695f",
    borderRadius: "5px",
    padding: "20px 20px",
    boxSizing: "border-box",
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-evenly",
  },
  openingsBtn:{
    backgroundColor:'#009688!important',
    '&:hover':{
      backgroundColor:'#00695f!important'
    }
  }
});

const StyledTextField = styled(TextField)(() => ({
    width:'448px',
  "& label.Mui-focused": {
    color: "#00695f",
    
    
  },
  //   '& .MuiInput-underline:after': {
  //     borderBottomColor: 'green',
  //   },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      border: "1px solid #00695f",
    },
    "&:hover fieldset": {
      borderColor: "#00695f",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#00695f",
    },
  },
}));
const TextStack=styled(Stack)(()=>({
    flexDirection:'row',
    width:'100%',
    justifyContent:'space-evenly',
    gap:'30px',
    flexWrap:'wrap'
    
}))
const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const ApplyOnline = () => {
  const classes = useStyles()
  const [data,setdata] = useState({
          name:'',
          email:'',
          mobile:'',
          degree:'',
          cname:'',
          message:'',
          position:''
  })
  const [feedback,setFeedback] = useState(false)
  // const [name,setName]=useState('')
  // const [email,setEmail]=useState('')
  // const [mobile,setMobile]=useState('')
  // const [degree,setDegree]=useState('')
  // const [cname,setCname]=useState('')
  // const [message,setMessage]=useState('')
  // const [position,setPosition]=useState('')
  const onInputChange=(e,key)=>{
   setdata({
    ...data,
    [key]:e.target.value
   })
  }
    const onCareerSubmit=()=>{
      if(data.name=='' || data.email=='' || data.mobile=='' || data.degree=='' || data.cname=='' || data.position==''){
          setFeedback(true)
          setOpen(true)
      }
      else{
        let payload={'Name':data.name,'Email':data.email,'Mobile-Number':data.mobile,'Degree':data.degree,'Company-Name':data.cname,'Position':data.position,'Message':data.message}
        axios.post("http://localhost:8000/careers",payload).then(res=>{
        setdata({
          name:'',
          email:'',
          mobile:'',
          degree:'',
          cname:'',
          message:'',
          position:''
        })
      })
      setFeedback(false)
      setOpen(true);
    }
      }
      
    const [open, setOpen] = React.useState(false);

 

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };
  return (
    <Container sx={{marginTop:'150px'}}>
      <Grid spacing={2} rowGap={'30px'} className={classes.applyGridBox}>
      <TextStack>
            <Typography variant="h5" sx={{fontSize:'36px',textUnderlineOffset:'12px',color:"#00695f"}}>Apply Online</Typography>
        </TextStack>
        <TextStack>
          <StyledTextField required id="outlined-required" label="Name" value={data.name} onChange={e => onInputChange(e,'name')} autoComplete={'off'}/>
          <StyledTextField required id="outlined-required" label="Email-ID" value={data.email} onChange={e => onInputChange(e,'email')} autoComplete={'off'}/>
        </TextStack>
        <TextStack>
          <StyledTextField required id="outlined-required" label="Mobile Number" value={data.mobile} onChange={e => onInputChange(e,'mobile')} autoComplete={'off'}/>
          <StyledTextField
            required
            id="outlined-required"
            label="Degree"
            value={data.degree}
            autoComplete={'off'}
            onChange={e => onInputChange(e,'degree')}
          />
        </TextStack>
        <TextStack>
            <StyledTextField required id="outlined-required" label="Previous Company Name" value={data.cname} onChange={e => onInputChange(e,'cname')} autoComplete={'off'}/>
            <StyledTextField required id="outlined-required" label="Which Position do you want to apply for" value={data.position} onChange={e => onInputChange(e,'position')} autoComplete={'off'}/>
            <StyledTextField
                id="outlined-multiline-static"
                label="Message"
                multiline
                rows={6}
                value={data.message}
                width={'100%'}
                autoComplete={'off'}
                onChange={e => onInputChange(e,'message')}
            />
        </TextStack>
        <TextStack>
        <Button type={"submit"} variant="contained" className={classes.openingsBtn} onClick={onCareerSubmit}>Submit</Button>
        {feedback?
          <Snackbar open={open} autoHideDuration={3000} onClose={handleClose}>
          <Alert onClose={handleClose} severity="error" sx={{ width: '100%' }}>
            Please Enter all the details
          </Alert>
        </Snackbar>:
        <Snackbar open={open} autoHideDuration={3000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="success" sx={{ width: '100%' }}>
          Successfully Submitted!
        </Alert>
      </Snackbar>
        }
        </TextStack>
      </Grid>
    </Container>
  );
};
export default ApplyOnline;