import {
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  Button,
  Stack,
  Box,
  SwipeableDrawer,
  List,
  ListItem,
  ListItemButton,
  styled,
  ButtonGroup,
  ListItemText,
  Collapse,
} from "@mui/material";
import MspandLogo from "../../Assets/mspand-logo.png";
import { NavLink } from "react-router-dom";
import "./MenuBar.css";
import MenuIcon from "@mui/icons-material/Menu";
import React from "react";
import { useState } from "react"
import { HashLink as Link } from "react-router-hash-link";
import Dropdown from "react-bootstrap/Dropdown";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";

const StyledButton = styled(Button)({
  minWidth: 0,
  padding: 0,
  boxSizing: "border-box",
  textDecoration: "none",
  fontWeight: "bold",
  color: "#00695f",
  fontFamily: "Lexend",
  letterSpacing: "1px",
  border: "none",
});

export const MenuBar = () => {
  const [state, setState] = useState({
    right: false,
  });
  const navlinks = [
    {
      navname: "Home",
      link: "/",
    },
    {
      navname: "About",
      link: "/about",
    },
    {
      navname: "Foot - Prints",
      link: "/foot-prints",
    },
    {
      navname: "Careers",
      link: "/careers",
    },
    {
      navname: "Contact",
      link: "/contact",
    },
  ];
  const [selectedIndex, setSelectedIndex] = React.useState(null);
  const [open1, setOpen1] = React.useState(false);

  const handleClick = () => {
    setOpen1(!open1);
  };
  const handleListItemClick = (event, index) => {
    setSelectedIndex(index);

    if (index === 3) {
      setOpen1(!open1);
    }
  };

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setState({ ...state, [anchor]: open });
  };
  const list = (anchor) => (
    <Box>
      <Box
        sx={{ width: anchor === "top" || anchor === "bottom" ? "auto" : 250 }}
        role="presentation"
        onClick={toggleDrawer(anchor, false)}
        onKeyDown={toggleDrawer(anchor, false)}
      >
        <List className="navList">
          {/* <ListItem disablePadding className="listButton"> */}
          <ListItemButton
            sx={{ padding: 0 }}
            selected={selectedIndex === 0}
            onClick={(event) => handleListItemClick(event, 0)}
            className={'listBtnHover1'}
          >
            <NavLink exact to={"/"} className={'sideBarItem'}>
              Home
            </NavLink>
          </ListItemButton>
          <ListItemButton
            sx={{ padding: 0 }}
            selected={selectedIndex === 1}
            onClick={(event) => handleListItemClick(event, 1)}
            className={'listBtnHover1'}
          >
            <NavLink exact to={"/about"} className={'sideBarItem'}>
              About
            </NavLink>
          </ListItemButton>
          <ListItemButton
            sx={{ padding: 0 }}
            selected={selectedIndex === 2}
            onClick={(event) => handleListItemClick(event, 2)}
            className={'listBtnHover1'}
          >
            <NavLink exact to={"/foot-prints"} className={'sideBarItem'}>
              Foot - Prints
            </NavLink>
          </ListItemButton>
          {/* <ListItemButton
            sx={{ padding: 0 }}
            selected={selectedIndex === 2}
            onClick={(event) => handleListItemClick(event, 2)}
            className={'listBtnHover1'}
          >
            <a className={'sideBarItem'} href="https://lighthearted-starlight-84e29f.netlify.app">DFT Test</a>
          </ListItemButton> */}
          
        </List>
      </Box>
      <Box>
        <List className="navList">
          <ListItemButton
            sx={{ padding: 0,gap:'6px' }}
            selected={selectedIndex === 3}
            onClick={(event) => handleListItemClick(event, 3)}
            className={'listBtnHover1'}
          >
            <NavLink exact to={"/careers"} style={{display:'flex',justifyContent:'space-between',alignItems:'center'}} className={'sideBarItem'}>
              Careers
              {open1 ? <ExpandLess /> : <ExpandMore />}
            </NavLink>
            
          </ListItemButton>
          <Collapse in={open1} timeout="auto" unmountOnExit>
            <Box
              sx={{
                width: anchor === "top" || anchor === "bottom" ? "auto" : 250,
              }}
              role="presentation"
              onClick={toggleDrawer(anchor, false)}
              onKeyDown={toggleDrawer(anchor, false)}
            >
              <List component="div" disablePadding>
                <ListItemButton sx={{padding:0}} className={'listBtnHover1'}>
                  <Link to="/careers#mspandGrowing" smooth style={{padding:'5px 10px 5px 37px'}} className={'sideBarItem'}>
                    MSPAND - The Growing Company
                  </Link>
                </ListItemButton>
                <ListItemButton sx={{padding:0}} className={'listBtnHover1'}>
                <Link
                    to="/careers#employeeBenefits"
                    smooth
                    style={{padding:'5px 10px 5px 37px'}}
                    className={'sideBarItem'}
                  >
                    Employee Benefits
                  </Link>
                </ListItemButton>
                <ListItemButton sx={{padding:0}}
                  selected={selectedIndex === 5}
                  onClick={(event) => handleListItemClick(event, 5)}
                  className={'listBtnHover1'}
                >
                    <NavLink exact to={"/job-Openings"} style={{padding:'5px 10px 5px 37px'}} className={'sideBarItem'}>
                      Current openings
                    </NavLink>
                </ListItemButton>
                <ListItemButton sx={{padding:0}} className={'listBtnHover1'}>
                <Link
                    to="/careers#testimonial"
                    smooth
                    style={{padding:'5px 10px 5px 37px'}}
                    className={'sideBarItem'}
                  >
                    Testimonial
                  </Link>
                  </ListItemButton>
                  
              </List>
            </Box>
          </Collapse>

          {/* <ListItemIcon>
                {index % 2 === 0 ? <InboxIcon /> : <MailIcon />}
              </ListItemIcon>
              <ListItemText primary={text.navname} /> */}

          {/* </ListItem> */}
        </List>
      </Box>
      <Box
        sx={{ width: anchor === "top" || anchor === "bottom" ? "auto" : 250 }}
        role="presentation"
        onClick={toggleDrawer(anchor, false)}
        onKeyDown={toggleDrawer(anchor, false)}
      >
        <List className="navList">
          {/* <ListItem disablePadding className="listButton"> */}
          <ListItemButton
            sx={{ padding: 0 }}
            selected={selectedIndex === 4}
            onClick={(event) => handleListItemClick(event, 4)}
            className={'listBtnHover1'}
          >
            <NavLink exact to={"/contact"} className={'sideBarItem'}>
              Contact
            </NavLink>
          </ListItemButton>
          </List>
          </Box>
    </Box>
  );

  // const [navBar, setNavBar] = useState(false);
  // const changeBackground = () => {
  //     console.log(window.scrollY)
  //     if (window.scrollY >= 80) {
  //         setNavBar(true);
  //     } else {
  //         setNavBar(false);
  //     }
  // }
  // window.addEventListener('scroll', changeBackground);

  return (
    <nav style={{ marginBottom: "75px" }}>
      <AppBar
        position="fixed"
        sx={{ backgroundColor: "whitesmoke" }}
        // className={navBar ? 'NavBarSet NavBarSetActive' : 'NavBarSet'}
      >
        <Toolbar>
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="logo"
          >
            <img
              src={MspandLogo}
              alt="Mspand Logo"
              style={{ height: "50px" }}
            />
          </IconButton>
          <Typography
            variant="h6"
            className="titleText"
            sx={{
              flexGrow: 1,
              fontWeight: "bold",
              fontSize: "30px",
              marginLeft: "20px",
            }}
          >
            MSPAND
            <span style={{ fontSize: "12px", fontStyle: "italic" }}>
              Every Engineers Dream...
            </span>
          </Typography>
          <Stack
            direction="row"
            spacing={2}
            display={{ xs: "none", md: "flex" }}
          >
            {/* <Link to='/careers#career' smooth>
                Something
              </Link> */}
            {/* {navlinks.map((navigate, i) => (
              <StyledButton color='inherit' key={i} onClick={(event) => handleListItemClick(event, i)}>
                <NavLink exact to={navigate.link}>
                  {navigate.navname}
                </NavLink>
              </StyledButton>
            ))} */}
            <StyledButton color="inherit" className={'listBtnHover'}>
              <NavLink exact to="/">
                Home
              </NavLink>
            </StyledButton>
            <StyledButton color="inherit"  className={'listBtnHover'}>
              <NavLink to="/about">About</NavLink>
            </StyledButton>
            <StyledButton color="inherit"  className={'listBtnHover'}>
              <NavLink to="/foot-prints">Foot - Prints</NavLink>
            </StyledButton>
            {/* <StyledButton color="inherit"  className={'listBtnHover'}>
              <a href="https://lighthearted-starlight-84e29f.netlify.app">DFT Test</a>
            </StyledButton> */}
            

            {/* <Nav className="me-auto"> */}

            <Dropdown as={ButtonGroup}>
              <Dropdown.Toggle
                split
                id="dropdown-split-basic"
                style={{
                  border: "none",
                  backgroundColor: "inherit",
                  padding: "0px",
                  boxSizing: "border-box",
                }}
              >
                <StyledButton color="inherit"  className={'listBtnHover'}>
                  <NavLink to="/careers">
                    Careers
                    <ArrowDropDownIcon style={{position:'relative',top:'-2px'}}/>
                  </NavLink>
                </StyledButton>
                
              </Dropdown.Toggle>
              <Dropdown.Menu className="dropdownMenu">
                <Dropdown.Item>
                  <Link
                    to="/careers#mspandGrowing"
                    smooth
                    className="dropdownitem"
                  >
                    MSPAND - The Growing Company
                  </Link>
                </Dropdown.Item>
                <Dropdown.Item>
                  <Link
                    to="/careers#employeeBenefits"
                    smooth
                    className="dropdownitem"
                  >
                    Employee Benefits
                  </Link>
                </Dropdown.Item>
                <Dropdown.Item>
                  <Link
                    to="/job-openings#currentOpenings"
                    smooth
                    className="dropdownitem"
                  >
                    Current openings
                  </Link>
                </Dropdown.Item>
                <Dropdown.Item>
                  <Link
                    to="/careers#testimonial"
                    smooth
                    className="dropdownitem"
                  >
                    Testimonial
                  </Link>
                </Dropdown.Item>
                
              </Dropdown.Menu>
            </Dropdown>
           
            <StyledButton color="inherit"  className={'listBtnHover'}>
              <NavLink to="/contact">Contact</NavLink>
            </StyledButton>

            {/* <StyledButton color="inherit">
            <NavLink to="/careers">Careers
              <NavDropdown title="Dropdown" id="basic-nav-dropdown">
              
                <NavDropdown.Item>
                  <Link to="/careers#career" smooth>
                    Something
                  </Link>
                </NavDropdown.Item>
                <NavDropdown.Item>
                  <Link to="/careers#career" smooth>
                    Something
                  </Link>
                </NavDropdown.Item>
              </NavDropdown>
              </NavLink>
              </StyledButton>
              <NavDropdown title="Dropdown" id="basic-nav-dropdown">
                <Link to="/careers#career" smooth>
                  Something
                </Link>
              </NavDropdown> */}
            {/* </Nav> */}
          </Stack>
          {/* <Button color='inherit'>
            <NavLink to='/careers'>Careers</NavLink>
          </Button> */}

          {/* <Button color='inherit'>
            <NavLink exact to='/'>Home</NavLink>
          </Button>
          <Button color='inherit'>
            <NavLink to='/about'>About</NavLink>
          </Button>
          <Button color='inherit'>
            <NavLink to='/pro-ser'>Pro - Ser</NavLink>
          </Button>
          <Button color='inherit'>
            <NavLink to='/careers'>Careers</NavLink>
          </Button>
          <Button color='inherit'>
            <NavLink to='/contact'>Contact</NavLink>  
          </Button> */}
          <Box display={{ xs: "block", md: "none" }}>
            {/* <IconButton size='large' edge='start' color='inherit' aria-label='logo'> */}
            {["left"].map((anchor) => (
              <React.Fragment key={anchor}>
                <StyledButton>
                  <MenuIcon
                    onClick={toggleDrawer(anchor, true)}
                    sx={{
                      cursor: "pointer",
                      padding: "4px",
                      color: "#009688",
                      fontSize: "40px",
                    }}
                  >
                    {anchor}
                  </MenuIcon>
                </StyledButton>
                <SwipeableDrawer
                  anchor={anchor}
                  open={state[anchor]}
                  onClose={toggleDrawer(anchor, false)}
                  onOpen={toggleDrawer(anchor, true)}
                >
                  {list(anchor)}
                </SwipeableDrawer>
              </React.Fragment>
            ))}
            {/* <MenuIcon sx={{fontSize:'35px'}}/> */}
            {/* </IconButton> */}
          </Box>
        </Toolbar>
      </AppBar>
      {/* <div style={{height:'100vh',backgroundColor:'red'}}>
        apple
      </div>
      <div style={{height:'100vh',backgroundColor:'blue'}}>
        apple
      </div>
      <div style={{height:'100vh',backgroundColor:'green'}}>
        apple
      </div>
      <Box id='#abc' style={{height:'100vh',backgroundColor:'yellow'}}>
        apple
      </Box> */}
    </nav>
  );
};